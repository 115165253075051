import React from "react"
import { graphql } from "gatsby"
import { themeGet } from "@styled-system/theme-get"
import { SimpleContentLayer } from "../../components/contentLayer/contentLayer"
import PageContainer from "../../components/page-container"
import { BackButton } from "../../components/buttons/buttons"
import { Container } from "../../components/container/container"
import { MenuWrapper } from "../../components/container/menuWrapper"

import { StoryMeta } from "../../content/stories/storyMeta"
import { StoryImage, StoryVideo } from "../../content/stories/storyImage"

import ROUTES from "../../config/constants/route"

import useWindowSize, { getSize } from "../../hooks/useWindowSize"
import { useDimensions } from "../../hooks/useDimensions"
import {
  formatImageData,
  formatImageDataSrcSet,
  formatMarkdownData,
} from "../../helpers/formatGraphqlData"
import styled from "@emotion/styled"
import { CustomReactMarkdown } from "../../components/customReactMarkdown"

export const query = graphql`
  query StoryPageQuery($slug: String!, $locale: String) {
    strapiStory(slug: { eq: $slug }, locale: { eq: $locale }) {
      id
      title
      slug
      location
      date
      videoLink
      featuredImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              sizes
              src
              srcSet
            }
          }
        }
      }
      content {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
    strapiStoryPage(locale: { eq: $locale }) {
      backToStories
    }
  }
`

const StoryPage = ({ data, location: locationWindow }) => {
  const { type } = useWindowSize()
  const [headRef, headSize] = useDimensions()
  const [contentRef, contentSize] = useDimensions()

  const { title, location, date, featuredImage, content, videoLink } =
    data?.strapiStory ?? {}
  const { backToStories } = data.strapiStoryPage ?? {}

  return (
    <PageContainer>
      <MenuWrapper>
        <SimpleContentLayer>
          <Container width="large">
            <div ref={headRef} className="-mt-8">
              {videoLink ? (
                <StoryVideo
                  className="react-player fixed-bottom"
                  url={videoLink}
                  width="100%"
                  height={headSize.width / 2}
                  controls={true}
                  fallback={
                    <StoryImage
                      srcSet={formatImageDataSrcSet(featuredImage)}
                      src={formatImageData(featuredImage)}
                      alt=""
                      height={headSize.width / 2}
                      minHeight={getSize(type, [150, 220, 300])}
                    />
                  }
                />
              ) : (
                <StoryImage
                  srcSet={formatImageDataSrcSet(featuredImage)}
                  src={formatImageData(featuredImage)}
                  alt=""
                  height={headSize.width / 2}
                  minHeight={getSize(type, [150, 220, 300])}
                />
              )}
            </div>
          </Container>
          <Container>
            <div className="py-3" />
            <div ref={contentRef} className="my-3">
              <StoryMeta title={title} place={location} date={date} />
            </div>
            <div className="py-5" />

            <FormatMarkdown>
              <CustomReactMarkdown source={formatMarkdownData(content)} />
            </FormatMarkdown>

            <div className="py-5" />
            <BackButton link={ROUTES.STORIES} text={backToStories} />
            <div className="py-5" />
          </Container>
        </SimpleContentLayer>
      </MenuWrapper>
    </PageContainer>
  )
}
export default StoryPage

const FormatMarkdown = styled.div`
  font-size: ${({ ...props }) => themeGet(`fontSize.lg`)(props)};
  color: ${({ ...props }) => themeGet(`colors.lightGray`)(props)};

  h1,
  h2,
  h3 {
    padding-bottom: 30px;
  }

  p {
    font-weight: ${({ ...props }) => themeGet(`fontWeight.light`)(props)};
  }
  a {
    font-weight: bold;
    text-decoration: underline;
  }
  img {
    padding-top: 20px;
    padding-bottom: 60px;
    max-width: 80%;
    margin: 0 auto;
  }
`
